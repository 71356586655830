<template>
  <div class="special_pack_wrapper">
    <div class="thumble">
      <b-img-lazy
        v-bind="mainProps"
        :src="imageUrl"
        alt="Not Found"
        onerror="this.src='assets/img/lesiurpackages_hotelsimg4.jpg'"
      ></b-img-lazy>

      <div class="overtext">
        <span class="tag">{{ item.basisName && item.basisName !== 'null' ? item.basisName : $t("basis-name." + item.basisCode.toLowerCase().trim()) }}</span>
        <span class="tag"> {{ getDuration(item.checkinDate, item.checkoutDate) }}  {{ $t("home.nights") }} </span>
      </div>

      <div class="price-remark">
        <div>{{ remark }}</div>
      </div>

    </div>

    <div class="contain_body">
      <div class="title-letter">{{ item.hotelName }}, {{ item.extendedProperties.destination.name }}</div>
      <h5 class="duration"><img src="/assets/img/theme2/calenda-icon.png" alt="calendar"> {{changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</h5>
      <h6 class="price-title">{{ $t("home.total-price-for-the-package") }}</h6>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h6 class="price"><sub>₪</sub>{{ discountedPrice }}</h6>
          <div>{{ item.priceRemark ? item.priceRemark : "" }}</div>
        </div>
        <div class="more-info">
          <a :href="productPageLink" v-if="isLanding">
            <div class="order-button btn-primary">
              {{ $t("home.slide-deal.order-button") }}
            </div>
          </a>
          <router-link :to="productPageLink" v-else>
            <div class="order-button btn-primary">
              {{ $t("home.slide-deal.order-button") }}
            </div>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BImgLazy } from 'bootstrap-vue';
import dayjs from 'dayjs';
// import dealProductLinkMixin from '@/mixins/dealProductLink';

export default {
  name: 'LesiurPackageItemTheme2',
  props: ['item'],
  components: {
    BImgLazy,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      dealType: 'GET_DEAL_TYPE',
      device: 'GET_DEVICE',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      marketerId: 'GET_MARKETER_ID',
    }),
    imageUrl() {
      const { item } = this;
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item.extendedProperties.hotelInfo.mainImageUrls[0] || item.extendedProperties.hotelConversion.imageUrl || 'assets/img/lesiurpackages_hotelsimg5.jpg';
      return url;
    },
    discountedPrice() {
      const { totalAfterDiscount, totalPrice } = this.item;
      if (this.isOdysseySite && !this.isFCLAgent) return Math.round(totalPrice);
      if (!this.isMemberLogin) return Math.round(totalAfterDiscount);
      const isAtlantis = this.item?.extendedProperties?.sourceName === 'ATLANTIS';
      const isCreditCardRemarkHotel = false;
      const { memberDiscountPercent } = this;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? totalAfterDiscount : totalAfterDiscount * (1 - memberDiscountPercent / 100);
      return Math.round(price);
    },
    commission() {
      const { isFCLAgent, item } = this;
      const { extendedProperties } = item;
      if (extendedProperties.sourceName === 'ATLANTIS') return 5;
      let { commission } = extendedProperties.hotelConversion;
      if (!isFCLAgent) {
        commission = Number(commission) - 5;
      }
      return commission;
    },
    productPageLink() {
      const { marketerId, item, imageUrl } = this;
      let url = `${item.productPageUrl}&dealId=${item.id}&mainimg=${imageUrl}`;
      if (marketerId && marketerId !== '') url = `${url}&marketerId=${marketerId}`;
      return url;
    },
    remark() {
      return this.supplierName === 'ATLANTIS' ? this.item?.roomRemark?.trim() || '' : '';
    },
    supplierName() {
      const { item } = this;
      const { extendedProperties } = item;
      return extendedProperties.sourceName;
    },
  },
  // mixins: [dealProductLinkMixin],
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      host: window.location.host,
    };
  },
  mounted() {
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    getDealCategory(ids) {
      return this.dealType.filter((item) => ids.indexOf(item.id) > -1).map((item) => (item.name)).join(', ');
    },
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
  },
};
</script>
<style scoped>
.special_pack_wrapper{
  border: 1px solid #D7DDE3;
  border-radius: 10px;
  overflow: hidden;
  width: 99%;
  margin: auto;
  direction: rtl;
}
.special_pack_wrapper .thumble {
    height: 230px;
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    overflow: hidden;
}
.special_pack_wrapper .thumble::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.6;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: 1;
}
.special_pack_wrapper .thumble img{
    width: 100%;
    object-fit: cover;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.special_pack_wrapper:hover .thumble img{
    webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.special_pack_wrapper .thumble .overtext{
    position: absolute;
    z-index: 9;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    top: 0;
    right: 0;
}
.special_pack_wrapper .thumble .overtext span.tag {
  background: linear-gradient(90deg, rgb(251 169 53) 0%, rgba(231,135,0,1) 75%);
  border-radius: 30px;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 6px 12px;
  margin-left: 7px;
}
.special_pack_wrapper .thumble .price-remark {
    box-sizing: border-box;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: right;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 0;
    z-index: 9;
    margin: 0;
    background: rgb(251 169 53);
    background: linear-gradient(90deg, rgb(251 169 53) 0%, rgba(231,135,0,1) 75%);
}
.special_pack_wrapper .contain_body {
    padding: 15px;
    box-sizing: border-box;
}
.special_pack_wrapper .contain_body .title-letter{
  padding-left: 10%;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  direction: rtl;
  font-size: 1.5rem;
}
.special_pack_wrapper:hover .contain_body .title-letter{
  padding-left: 0;
  overflow: inherit;
  max-height: inherit;
}
.special_pack_wrapper .contain_body .duration{
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}
.special_pack_wrapper .contain_body  .duration img{
  max-width:14px;
  margin-left:5px;
}
.special_pack_wrapper .contain_body .price-title{
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  display: flex;
  align-items: center;
}
.special_pack_wrapper .contain_body .price{
  font-weight: 600;
  font-size: 22px;
  line-height: 18px;
  color: #000000;
  direction: ltr;
  margin: 0;
}
.special_pack_wrapper .contain_body .price sub{
  bottom:0em;
}
.special_pack_wrapper .contain_body .order-button{
  background: #FF0000;
  border-radius: 22px;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #FFFFFF;
  padding: 9px 15px;
  min-width: 105px;
}
</style>
